import { createTheme } from "@mui/material";

export const theme =  createTheme({
    palette: {
      primary: {
        main: '#432818',        
        light: '#b4a9a3',
        dark: '#1b100a',  
        contrastText: '#fffefc' 
      },
      secondary: {
          main: '#e3d5ca',      
          light: '#f9f7f4',   
          dark: '#9f958d',  
          contrastText: '#191816'  
      },
      info: {
          main: '#007BFF',      
          light: '#e6f2ff',   
          dark: '#001933',
          contrastText: '#ffffff'    
      },
      error: {
          main: '#dc3545',      
          light: '#f8d7da',   
          dark: '#842029',    
      },
      warning: {
          main: '#ffc107',      
          light: '#fff3cd',   
          dark: '#664d03',    
      },
      success: {
          main: '#28a745',      
          light: '#d4edda',   
          dark: '#0c7d1e',    
      },
      text: {
          primary: '#343a40',   
          secondary: '#6c757d', 
          disabled: '#adb5bd',  
      },
      common: {
        black: '#191816',
        white: '#ffffff'
      }
    },
    typography: {
      h1: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: 'calc(70px + 0.5vw)',
      },
      h2: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: 'calc(55px + 0.5vw)'
      },
      h3: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: 'calc(30px + 0.5vw)'
      },
      h4: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: 'calc(22px + 0.5vw)'
      },
      h5: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: 'calc(18px + 0.5vw)'
      },
      h6: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: 'calc(14px + 0.5vw)'
      },
      body1: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: 'calc(14px + 0.2vw)',
        lineHeight: 1.5
      },
      body2: {
        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
        fontSize: 'calc(12px + 0.2vw)',
        lineHeight: 1.5
      }
    },
    components: {
        MuiButton: {
            defaultProps: {
              variant: 'outlined'
            },
            styleOverrides: {
                root: {
                   fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                },
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                }
            }
        },
    }
});
import { Abc, Home, Menu, Phone } from "@mui/icons-material";
import { AppBar, Box, Button, Drawer, IconButton, Link, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import logo from "../../assets/logo.png";

const Navigation = () => {
    const [open, setOpen] = useState(false)

    return(
        <AppBar style={{boxShadow: "none"}}>
            <Toolbar style={{background: "#432818", display: "flex", justifyContent: "space-between"}}>
                <Box style={{display: "flex", alignItems: "center"}}>
                    <IconButton edge="start" color="inherit" aria-label="menu" sx={{
                        display: {xs: "block", sm: "block", md: "none", lg: "none"
                    }}} onClick={() => setOpen(true)}>
                        <Menu />
                    </IconButton>
                    <img src={logo} alt="ResumLink" style={{width: "50px", height: "50px"}} />
                    <Typography variant="h5" color="#e3d5ca" style={{letterSpacing: "1px"}}>
                        ResumLink
                    </Typography>
                </Box>
                <Box sx={{display: {xs: "none", sm: "none", md: "block", lg: "block"}}}>
                    <Link href="#home" color="#f5ebe0" underline="none" sx={{mr: 2}}>
                        Home
                    </Link>
                    <Link href="#about" color="#f5ebe0" underline="none" sx={{mr: 2}}>
                        About
                    </Link>
                    <Link href="#contact" color="#f5ebe0" underline="none" sx={{mr: 2}}>
                        Contact
                    </Link>
                    <Button variant="outlined" style={{
                        padding: "5px 10px",
                        color: "#f5ebe0",
                        border: "1px solid #f5ebe0",
                        borderRadius: "20px",
                        fontSize: "12px",
                        letterSpacing: "3px"
                    }} onClick={() => window.location.href = "https://portal.resumlink.com/"}>
                        Login/Signup
                    </Button>
                </Box>
            </Toolbar>
            <Drawer open={open}>
                <Box style={{width: 200, height: "100%", background: "#e3d5ca"}}>
                    <List>
                        <ListItemButton onClick={() => {
                            window.location.href = "#home";
                            setOpen(false);
                        }}>
                            <ListItemIcon>
                                <Home />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItemButton>
                        <ListItemButton onClick={() => {
                            window.location.href = "#about";
                            setOpen(false);
                        }}>
                            <ListItemIcon>
                                <Abc />
                            </ListItemIcon>
                            <ListItemText primary="About"/>
                        </ListItemButton>
                        <ListItemButton onClick={() => {
                            window.location.href = "#contact";
                            setOpen(false);
                        }}>
                            <ListItemIcon>
                                <Phone />
                            </ListItemIcon>
                            <ListItemText primary="Contact"/>
                        </ListItemButton>
                    </List>
                </Box>
            </Drawer>
        </AppBar>
    )
}

export default Navigation;

import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import {default as template1} from './assets/template1.png';
import {default as template2} from './assets/template2.png';
import {default as template3} from './assets/template3.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import './App.css';
import { Assessment, BackupTable, CloudSync, Drafts, Launch, LinkedIn, Mail, MoreTime, PresentToAll, TrendingUp, ViewQuilt, Web } from '@mui/icons-material';
import portal from "./assets/portal.png";
import Navigation from './components/Navigation';
import logo from "./assets/logo.png";
import { useEffect } from 'react';

const App = () => {
  useEffect(() => {
    fetch('https://freeipapi.com/api/json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json().then(jsonResponse => {
        fetch('https://api.resumlink.com/pviewerApi/websitesession/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            requestIp: jsonResponse.ipAddress,
            country: jsonResponse.countryName,
            countryCode: jsonResponse.countryCode,
            state: jsonResponse.regionName,
            city: jsonResponse.cityName,
            latitude: jsonResponse.latitude,
            longitude: jsonResponse.longitude
          })
        }).then(response => response.json().then(jsonResponse => {
          if(!jsonResponse.ok){
            console.error('Failed to store location details', jsonResponse)
          }
        })).catch(error => console.error('Failed to store location details', error))
    })).catch(error => console.error('Cannot parse response', error))
    .catch(error => 
      console.error('Failed to capture location', error)
    )
  }, [])

  return (
    <Box>
      <Navigation />
      <Grid container style={{marginTop: "60px"}}>
          <Grid item xs={0} sm={0} md={0} lg={2}>
            <Box sx={{position: {lg: "absolute"}, top: "18%", left: "3%", zIndex: 2, display: {xs: "none", sm: "none", md: "none", lg: "block"}}}>
              <Box 
                style={{
                  display: "flex"
                }}
              >
                <Box
                  style={{
                    width: "200px",
                    height: "200px",
                    border: "12px solid #fff",
                    borderRadius: "10px",
                    transform: "rotate(-15deg)",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img 
                    src={template1} 
                    alt="template1" 
                    width="100%"
                    height="100%"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      borderRadius: "5px",
                      border: "1.8px solid #e5e5e5"
                    }}
                  />
                </Box>
                <Box
                  style={{
                    width: "200px",
                    height: "200px",
                    border: "12px solid #fff",
                    borderRadius: "10px",
                    transform: "rotate(15deg)",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "-30px",
                    marginTop: "60px"
                  }}
                >
                  <img 
                    src={template2} 
                    alt="template1" 
                    width="100%"
                    height="100%"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      borderRadius: "5px",
                      border: "1.8px solid #e5e5e5"
                    }}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  width: "200px",
                  height: "200px",
                  border: "12px solid #fff",
                  borderRadius: "10px",
                  transform: "rotate(5deg)",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "-80px",
                  marginLeft: "50px"
                }}
              >
                <img 
                  src={template3} 
                  alt="template1" 
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    borderRadius: "5px",
                    border: "1.8px solid #e5e5e5"
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={11} lg={9} sx={{
            width: "100%",
            height: {lg: "70vh"},
            backgroundColor: "#f5ebe0",
            position: "relative"
          }}>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: {xs: "center", sm: "center", md: "center", lg: "flex-end"},
              padding: "5vw",
            }}>
              <Typography variant="h1" style={{paddingBottom: "0.5vw", color: "#463f3a"}}>
                ResumLink
              </Typography>
              <Typography variant="body1">
                Free up your time for landing your dream job while we manage your application process!
              </Typography>
              <Button variant="contained" style={{
                marginTop: "4vw",
                backgroundColor: "#432818",
                color: "#e3d5ca",
                borderRadius: "50px",
                padding: "15px 25px",
                fontSize: "calc(9px + 0.5vw)",
                letterSpacing: "0.1rem",
              }} onClick={() => window.location.href = "https://portal.resumlink.com/"}>
                Get Started For Free
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} style={{background: "#432818"}} id="home">
              <Box sx={{
                display: "flex", 
                flexDirection: {xs: "row", sm: "row", md: "column", lg: "column"},
                gap: {xs: 8, sm: 8}, 
                alignItems: "center", 
                justifyContent: "center", 
                height: "100%"
              }}> 
                <InstagramIcon style={{color: "#e3d5ca", fontSize: "calc(40px + 1vw)", margin: "2vw 0", cursor: 'pointer'}} onClick={() => window.open("https://www.instagram.com/resumlink")} />
                <LinkedIn style={{color: "#e3d5ca", fontSize: "calc(40px + 1vw)", margin: "2vw 0", cursor: 'pointer'}} onClick={() => window.open("https://www.linkedin.com/company/resumlink")}/>
                <a href="mailto:resumlink@tedoratech.com">
                  <Mail style={{color: "#e3d5ca", fontSize: "calc(40px + 1vw)", margin: "2vw 0", cursor: 'pointer'}} />
                </a>
              </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "5vw"}}>
            <Grid container style={{marginTop: "80px"}}>
              <Grid item xs={12} sm={12} md={4} lg={4} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Box sx={{
                  width: "260px", 
                  height: "260px", 
                  borderRadius: "50%", 
                  border: "5px solid #e3d5ca", 
                  background: "#f5ebe0", 
                  position: {xs: "relative", sM: "relative", md: "absolute", lg: "absolute"}, 
                  zIndex: 1, 
                  padding: "20px"
                }}>
                  <Box style={{display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                    <Typography variant="h3" style={{color: "#463f3a", textAlign: "center", fontFamily: "fantasy"}}>
                        All Under One Roof
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{
                    width: {xs: "0%", sm: "0%", md: "100%", lg: "100%"},
                    height: "100%", 
                    background: "#432818"
                   }}></Box>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Box style={{background: "#432818", padding: "20px"}}>
                  <Grid container>
                    <Grid item xs={6} sm={6} md={3} lg={3} sx={{
                      display: "flex", 
                      flexDirection: "column", 
                      justifyContent: "center", 
                      alignItems: "center",
                      marginTop: {xs: "20px", sm: "20px", md: "0", lg: "0"}
                      }}>
                      <Box style={{
                            width: "20px", 
                            height: "20px", 
                            borderRadius: "50%", 
                            border: "1px solid #e3d5ca", 
                            padding: "1vw", 
                            display: "flex", 
                            justifyContent: "center", 
                            alignItems: "center",
                            margin: "0 7vw"
                          }}>
                            <Web style={{color: "#f5ebe0", fontSize: "calc(10px + 1vw)"}}/>
                      </Box>
                      <Typography variant='h6' style={{textAlign: "center", textTransform: "uppercase", color: "#f5ebe0", fontSize: "calc(8px + 0.5vw)", marginTop: "1vw", letterSpacing: "2px"}}>Portfolios</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} sx={{
                        display: "flex", 
                        flexDirection: "column", 
                        justifyContent: "center", 
                        alignItems: "center",
                        marginTop: {xs: "20px", sm: "20px", md: "0", lg: "0"}
                    }}>
                      <Box style={{
                            width: "20px", 
                            height: "20px", 
                            borderRadius: "50%", 
                            border: "1px solid #e3d5ca", 
                            padding: "1vw", 
                            display: "flex", 
                            justifyContent: "center", 
                            alignItems: "center",
                            margin: "0 7vw"
                          }}>
                            <Drafts style={{color: "#f5ebe0", fontSize: "calc(10px + 1vw)"}}/>
                      </Box>
                      <Typography variant='h6' style={{textAlign: "center", textTransform: "uppercase", color: "#f5ebe0", fontSize: "calc(8px + 0.5vw)", marginTop: "1vw", letterSpacing: "2px"}}>Cover Letters</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} sx={{
                      display: "flex", 
                      flexDirection: "column", 
                      justifyContent: "center", 
                      alignItems: "center",
                      marginTop: {xs: "20px", sm: "20px", md: "0", lg: "0"}
                    }}>
                      <Box style={{
                            width: "20px", 
                            height: "20px", 
                            borderRadius: "50%", 
                            border: "1px solid #e3d5ca", 
                            padding: "1vw", 
                            display: "flex", 
                            justifyContent: "center", 
                            alignItems: "center",
                            margin: "0 7vw"
                          }}>
                            <BackupTable style={{color: "#f5ebe0", fontSize: "calc(10px + 1vw)"}}/>
                      </Box>
                      <Typography variant='h6' style={{textAlign: "center", textTransform: "uppercase", color: "#f5ebe0", fontSize: "calc(8px + 0.5vw)", marginTop: "1vw", letterSpacing: "2px"}}>Job Applications</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} sx={{
                      display: "flex", 
                      flexDirection: "column", 
                      justifyContent: "center", 
                      alignItems: "center",
                      marginTop: {xs: "20px", sm: "20px", md: "0", lg: "0"}
                    }}>
                      <Box style={{
                            width: "20px", 
                            height: "20px", 
                            borderRadius: "50%", 
                            border: "1px solid #e3d5ca", 
                            padding: "1vw", 
                            display: "flex", 
                            justifyContent: "center", 
                            alignItems: "center",
                            margin: "0 7vw"
                          }}>
                            <Assessment style={{color: "#f5ebe0", fontSize: "calc(10px + 1vw)"}}/>
                      </Box>
                      <Typography variant='h6' style={{textAlign: "center", textTransform: "uppercase", color: "#f5ebe0", fontSize: "calc(8px + 0.5vw)", marginTop: "1vw", letterSpacing: "2px"}}>Assessments</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "5vw"}} id="about">
            <Grid container style={{marginTop: "80px"}}>
              <Grid item xs={12} sm={12} md={4} lg={4} sx={{
                  display: {xs: "block", sm: "block", md: "none", lg: "none"}
              }}>
                <Box style={{display: "flex", justifyContent: "center"}}>
                  <Box style={{
                    width: "100px", 
                    height: "100px", 
                    background: "#432818", 
                    transform: "rotate(45deg)", 
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center", 
                    alignItems: "center"
                  }}>
                      <MoreTime style={{fontSize: "50px", color: "#f5ebe0", transform: "rotate(-45deg)"}}/>
                  </Box>
                </Box>
                <Box style={{display: "flex", justifyContent: "center"}}>
                  <Box style={{
                    width: "100px", 
                    height: "100px", 
                    background: "#f5ebe0", 
                    transform: "rotate(45deg)", 
                    borderRadius: "20px", 
                    margin: "0 2vw",
                    display: "flex",
                    justifyContent: "center", 
                    alignItems: "center"
                  }}>
                    <CloudSync style={{fontSize: "50px", color: "#432818", transform: "rotate(-45deg)"}}/>
                  </Box>
                  <Box style={{
                    width: "150px", 
                    height: "150px", 
                    background: "#432818", 
                    transform: "rotate(45deg)", 
                    borderRadius: "20px", 
                    margin: "0 2vw"
                  }}>
                    <img src={logo} alt="ResumLink" style={{width: "80%", height: "80%", objectFit: "cover", objectPosition: "center"}}/>
                  </Box>
                </Box>
                <Box style={{display: "flex", justifyContent: "center"}}>
                  <Box style={{
                    width: "100px", 
                    height: "100px", 
                    background: "#e3d5ca", 
                    transform: "rotate(45deg)", 
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <TrendingUp style={{fontSize: "50px", color: "#432818", transform: "rotate(-45deg)"}}/>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box style={{padding: "5vw 10vw"}}>
                  <Typography variant="h2" style={{color: "#463f3a"}}>Platform Specifics</Typography>
                  <Typography variant="body1" style={{marginTop: "2vw"}}>
                      Are you tired of spending countless hours on mundane tasks like crafting cover letters, updating your portfolio, juggling multiple Excel sheets, and endlessly navigating through various job search websites? Introducing ResumLink, the ultimate time-saving platform that consolidates all your job search and application needs in one convenient location. By streamlining your job search process, ResumLink empowers you to make the most efficient use of your time, ensuring that every aspect of your application process is optimized for success. From crafting personalized cover letters to updating your portfolio with ease, ResumLink simplifies every step of the job search journey, giving you the competitive edge you need to stand out in today's competitive job market.
                  </Typography>
                  <Typography variant="body1" style={{marginTop: "2vw"}}>
                      By streamlining your job search process, ResumLink empowers you to make the most efficient use of your time, ensuring that every aspect of your application process is optimized for success. From crafting personalized cover letters to updating your portfolio with ease, ResumLink simplifies every step of the job search journey, giving you the competitive edge you need to stand out in today's competitive job market.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} sx={{
                  display: {xs: "none", sm: "none", md: "block", lg: "block"}
              }}>
                <Box style={{display: "flex", justifyContent: "center"}}>
                  <Box style={{
                    width: "100px", 
                    height: "100px", 
                    background: "#432818", 
                    transform: "rotate(45deg)", 
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center", 
                    alignItems: "center"
                  }}>
                      <MoreTime style={{fontSize: "50px", color: "#f5ebe0", transform: "rotate(-45deg)"}}/>
                  </Box>
                </Box>
                <Box style={{display: "flex", justifyContent: "center"}}>
                  <Box style={{
                    width: "100px", 
                    height: "100px", 
                    background: "#f5ebe0", 
                    transform: "rotate(45deg)", 
                    borderRadius: "20px", 
                    margin: "0 2vw",
                    display: "flex",
                    justifyContent: "center", 
                    alignItems: "center"
                  }}>
                    <CloudSync style={{fontSize: "50px", color: "#432818", transform: "rotate(-45deg)"}}/>
                  </Box>
                  <Box style={{
                    width: "150px", 
                    height: "150px", 
                    background: "#432818", 
                    transform: "rotate(45deg)", 
                    borderRadius: "20px", 
                    margin: "0 2vw",
                    display: "flex",
                    justifyContent: "center", 
                    alignItems: "center"
                  }}>
                    <img src={logo} alt="ResumLink" style={{
                      width: "80%", 
                      height: "80%", 
                      objectFit: "cover", 
                      objectPosition: "center",
                      transform: "rotate(-45deg)"
                    }}/>
                  </Box>
                </Box>
                <Box style={{display: "flex", justifyContent: "center"}}>
                  <Box style={{
                    width: "100px", 
                    height: "100px", 
                    background: "#e3d5ca", 
                    transform: "rotate(45deg)", 
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <TrendingUp style={{fontSize: "50px", color: "#432818", transform: "rotate(-45deg)"}}/>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "5vw"}}>
            <Box style={{position: "relative"}}>
              <Box sx={{display: {xs: "none", sm: "none", md: "none", lg: "block"}}}>
                <img src={portal} alt="portal" width="100%" height="100%"/>
              </Box>
              <Box sx={{
                position: {lg: "absolute"},
                top: 0,
                left: 0,
                background: "#432818",
                opacity: 0.9,
                width: "100%",
                height: "100%",
                zIndex: 1
              }}></Box>
              <Box sx={{display: "flex", justifyContent: "center"}}>
                <Box sx={{top: "10%", zIndex: 2, display: {xs: "block", sm: "block", md: "block", lg: "none"}, width: "80%", overflow: "hidden"}}>
                  <Box 
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      overflow: "hidden"
                    }}
                  >
                    <Box
                      style={{
                        width: "200px",
                        height: "200px",
                        border: "12px solid #fff",
                        borderRadius: "10px",
                        transform: "rotate(-15deg)",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <img 
                        src={template1} 
                        alt="template1" 
                        width="100%"
                        height="100%"
                        style={{
                          objectFit: "cover",
                          objectPosition: "center",
                          borderRadius: "5px",
                          border: "1.8px solid #e5e5e5"
                        }}
                      />
                    </Box>
                    <Box
                      style={{
                        width: "200px",
                        height: "200px",
                        border: "12px solid #fff",
                        borderRadius: "10px",
                        transform: "rotate(15deg)",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "-30px",
                        marginTop: "60px"
                      }}
                    >
                      <img 
                        src={template2} 
                        alt="template1" 
                        width="100%"
                        height="100%"
                        style={{
                          objectFit: "cover",
                          objectPosition: "center",
                          borderRadius: "5px",
                          border: "1.8px solid #e5e5e5"
                        }}
                      />
                    </Box>
                  </Box>
                  <Box style={{
                    display: "flex",
                    justifyContent: "center",
                  }}>
                    <Box
                      style={{
                        width: "200px",
                        height: "200px",
                        border: "12px solid #fff",
                        borderRadius: "10px",
                        transform: "rotate(5deg)",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "-80px"
                      }}
                    >
                      <img 
                        src={template3} 
                        alt="template1" 
                        width="100%"
                        height="100%"
                        style={{
                          objectFit: "cover",
                          objectPosition: "center",
                          borderRadius: "5px",
                          border: "1.8px solid #e5e5e5"
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Grid container sx={{
                position: {lg: "absolute"},
                top: 0,
                left: 0,
                zIndex: 2,
                widht: "100%"
              }}>
                <Grid item xs={12} sm={12} md={7} lg={7}>
                  <Box
                    sx={{
                      marginTop: {xs: "10%", sm: "10%", md: "10%", lg: "35%"},
                      padding: "5vw"
                    }}
                  >
                    <Typography variant="h2" sx={{color: {xs: "#432818", sm: "#432818", md: "#432818", lg:"#f5ebe0"} }}>
                      No more endless searching through clutter or worrying about lost files!
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Box
                    sx={{
                      maxWidth: {lg: "90%"},
                      margin: {xs: "2vw 5vw", sm: "2vw 5vw", md: "2vw", lg: "8% 10% 0 0"},
                      borderRadius: "10px",
                      background: "#e3d5ca",
                      display: "flex",
                      padding: "5vw",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column"
                    }}
                  >
                    <Typography variant="body1">
                      Have you ever found yourself overwhelmed by the sheer chaos of managing numerous folders scattered across your laptops and cloud storage platforms? It's a common struggle faced by many in today's digital age. But fear not, because with our centralized and secure cloud environment, all of this chaos is a thing of the past. All your data is meticulously organized and easily accessible whenever you need it. Enjoy the peace of mind that comes with knowing your files are safely stored and readily available at your fingertips. Simplify your digital life and say hello to a stress-free way of managing your data.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "5vw"}}>
            <Grid container style={{marginTop: "50px"}}>
                <Grid item xs={12} sm={12} md={6} lg={6} style={{padding: "0 5vw"}}>
                  <Box style={{
                    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                    borderRadius: "20px",
                    padding: "4vw",
                  }}>
                    <Typography variant='h4'>Focus on honing your skills, not crafting your portfolios!</Typography>
                    <Typography variant='body2' style={{marginTop: "2vw"}}>
                      We fully manage your online portfolios right from generating the page to making it accessible and available to the public at no cost. Our cutting-edge AI technology helps you transform your resume into polished content, perfectly tailored to your chosen template within minutes! But that's not where our services end. We offer comprehensive analytics that provides detailed insights into the number of views and the top locations from which your portfolio is being accessed. This invaluable information empowers you with the knowledge you need to optimize your portfolio's reach and effectiveness, allowing you to always stay ahead of the curve in showcasing your professional achievements and capabilities.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{position: 'relative', padding: {xs: "0 5vw", sm: "0 5vw", md: "0", lg: "0"}}}>
                  <Box sx={{
                    position:{md: "absolute", lg: "absolute"},
                    marginTop: {xs: "10%", sm: "10%", md: "0", lg: "0"},
                    top: 20,
                    left: 0,
                    zIndex: 1
                  }}>
                    <Box sx={{
                      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                      borderRadius: "5px",
                      padding: "1.2vw",
                      width: {xs: "100%", sm: "100%", md: "70%", lg: "70%"},
                      marginBottom: "2vw",
                      background: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <Box style={{
                        clipPath: "polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)",
                        width: "20px",
                        height: "20px",
                        background: "#f5ebe0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "1vw",
                      }}>
                        <PresentToAll style={{color: "#463f3a", fontSize: "calc(10px + 1vw)"}}/>
                      </Box>
                      <Box style={{paddingLeft: "2vw"}}>
                        <Typography variant="h6">Upload your Resume</Typography>
                        <Typography variant="body2">All we need is just your resume! To create your template start by uploading your resume after you login to the portal</Typography>
                      </Box>
                    </Box>
                    <Box sx={{
                      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                      borderRadius: "5px",
                      padding: "1.2vw",
                      width: {xs: "100%", sm: "100%", md: "70%", lg: "70%"},
                      marginBottom: "2vw",
                      background: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <Box style={{
                        clipPath: "polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)",
                        width: "20px",
                        height: "20px",
                        background: "#f5ebe0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "1vw",
                      }}>
                        <ViewQuilt style={{color: "#463f3a", fontSize: "calc(10px + 1vw)"}}/>
                      </Box>
                      <Box style={{paddingLeft: "2vw"}}>
                        <Typography variant="h6">Choose the Template</Typography>
                        <Typography variant="body2">Once you upload your resume, you will be presented with professional template options from which you can choose one</Typography>
                      </Box>
                    </Box>
                    <Box sx={{
                      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                      borderRadius: "5px",
                      padding: "1.2vw",
                      width: {xs: "100%", sm: "100%", md: "70%", lg: "70%"},
                      marginBottom: "2vw",
                      background: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <Box style={{
                        clipPath: "polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)",
                        width: "20px",
                        height: "20px",
                        background: "#f5ebe0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "1vw",
                      }}>
                        <Launch style={{color: "#463f3a", fontSize: "calc(10px + 1vw)"}}/>
                      </Box>
                      <Box style={{paddingLeft: "2vw"}}>
                        <Typography variant="h6">Review and Share</Typography>
                        <Typography variant="body2">That is it, within minutes you will have the link to your portfolio. All you need to do is review the content and share it</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{display: "flex", justifyContent: "flex-end", height: {md: "100%", lg: "100%"}}}>
                    <Box style={{
                      background: "#e3d5ca",
                      width: "50%",
                      height: "100%"
                    }}></Box>
                  </Box>
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "5vw"}}>
            <Grid container style={{marginTop: "50px"}}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box style={{
                  maxWidth: "100%",
                  height: "100%",
                  padding: "5vw 5vw 0 5vw",
                  background: "#f5ebe0"
                }}>
                  <Typography variant='h3'>Monitor the progress of each job application seamlessly with a single consolidated view</Typography>
                  <Typography variant='body1' style={{marginTop: "3vw", lineHeight: "1.8"}}>
                    Keeping track of numerous job applications can be a daunting task, often resulting in lost statuses and missed opportunities. Profile Viewer revolutionizes job application management by providing a streamlined platform where you can effortlessly track the progress of each application with just a few details. Linked cover letters and resumes are integrated seamlessly, offering comprehensive insights into your job applications. Unlike traditional methods like Excel sheets, our user-friendly interface allows for easy sorting, filtering, and archiving of job details with a simple click, ensuring efficient management of your job responses and interests.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box style={{
                  borderLeft: "10px solid #432818",
                  maxWidth: "100%",
                  height: "100%",
                  padding: "5vw 3vw 0 3vw"
                }}>
                  <Typography variant='h4'>To top it all,</Typography>
                  <Typography variant="body2" style={{lineHeight: "1.8", marginTop: "2vw"}}>
                    The ResumeLink provides the top 10 AI-suggested behavioral and technical assessments tailored to your job role, providing valuable insights into the interviewer's expectations. These assessments are designed to strengthen your communication skills and foster an understanding of the anticipated work culture and leadership qualities. Our innovative analytical dashboards offer various metrics such as relevance, clarity, and role alignment for each of your answers. This in-depth analysis empowers you to identify areas of strength and areas for improvement, ensuring that you are fully prepared and confident when facing your interview.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: "5vw"}} id="contact">
            <Box style={{background: "#e3d5ca", padding: "5vw", marginTop: "80px"}}>
              <Typography variant="h2" style={{color: "#432818", textAlign: "center", opacity: 1}}>ResumLink</Typography>
              <Box style={{display: "flex", gap: 60, justifyContent: "center", marginTop: "2vw"}}>
                <Box style={{background: "#432818", borderRadius: "50%", padding: "8px", width: "28px", height: "28px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <InstagramIcon style={{color: "#e3d5ca", fontSize: "calc(10px + 1vw)", cursor: 'pointer'}} onClick={() => window.open("https://www.instagram.com/resumlink")} />
                </Box>
                <Box style={{background: "#432818", borderRadius: "50%", padding: "8px", width: "28px", height: "28px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <LinkedIn style={{color: "#e3d5ca", fontSize: "calc(10px + 1vw)", cursor: 'pointer'}} onClick={() => window.open("https://www.linkedin.com/company/resumlink")} />
                </Box>
                <Box style={{background: "#432818", borderRadius: "50%", padding: "8px", width: "28px", height: "28px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <a href="mailto:resumlink@tedoratech.com">
                  <Mail style={{color: "#e3d5ca", fontSize: "calc(10px + 1vw)", margin: "2vw 0", cursor: 'pointer'}} />
                </a>
                </Box>
              </Box>
              <Box style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "2vw"}}>
                <Typography variant="h6" style={{color: "#432818"}}>
                  Subscribe to our Newsletter
                </Typography>
                <TextField 
                  variant="outlined" 
                  sx={{
                    background: "#ffffff", 
                    borderRadius: "8px", 
                    width: {xs: "100%", sm: "100%", md: "60%", lg:"30%"},
                    marginTop: "0.5vw"
                  }} 
                  placeholder="Enter your email address"
                />
                <Button variant="contained" style={{
                  marginTop: "1vw",
                  backgroundColor: "#432818",
                  color: "#e3d5ca",
                  borderRadius: "50px",
                  padding: "0.5vw 2vw",
                  fontSize: "calc(8px + 0.5vw)",
                  letterSpacing: "0.3rem",
                }}>
                  Subscribe
                </Button>
              </Box>
            </Box>
            <Box style={{display: "flex", justifyContent: "center", background: "#432818"}}>
              <Typography variant='body2' style={{color: "#e3d5ca", padding: "1vw 0"}}>© 2024 ResumLink. All rights reserved.</Typography>
            </Box>
          </Grid>
      </Grid>
    </Box>
  )
}

export default App;
